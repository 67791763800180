
export default {
  name: "Index",
  data() {
    return {
      success: false,
      errored: false,
      loading: false,
      name: "",
      email: "",
    };
  },
  methods: {
    addContact() {
      this.loading = true;
      this.$axios
        .post("/join/postContact", {
          name: this.name,
          email: this.email,
        })
        .then(() => {
          this.loading = false;
          this.success = true;
          this.errored = false;
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
          this.errored = true;
        });
    },
  },
};
